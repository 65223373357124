@import "../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	gap: $logan-spacing-16;
	padding: $logan-spacing-16;

	.tableContainer {
		padding: $logan-spacing-20;
		gap: $logan-spacing-24;
		border: $logan-border-width-2 solid $logan-outline-gray;
		border-radius: $logan-border-radius-16;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);

		min-width: 310px;

		.loanTableHeader {
			padding: $logan-spacing-10 $logan-spacing-0;
		}

		.downloadIcon {
			font-size: $logan-font-size-20;
		}
	}
}
