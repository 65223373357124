@import "../../../styles/build/scss/variables";

.react-calendar__tile--active {
	background-color: $logan-button-primary-enabled !important;
	color: $logan-text-white !important;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
	stroke: $logan-button-primary-enabled !important;
}

.react-calendar__tile:disabled abbr {
	color: $logan-text-light-gary;
}

.datepicker-error {
	border-radius: $logan-border-radius-6 !important;
	// missing token
	border: $logan-border-width-2 solid $logan-destructive-enabled !important;
	background: rgba(236, 86, 86, 0.15) !important;

	.react-date-picker__inputGroup,
	.react-date-picker__clear-button,
	.react-date-picker__calendar-button {
		// missing token
		background-color: rgba(252, 230, 230, 1) !important;
		border-radius: $logan-border-radius-0 !important;
	}
}
