@import "../../../styles/build/scss/variables";

.MuiOutlinedInput-notchedOutline,
.Mui-disabled .MuiOutlinedInput-notchedOutline {
	border: 0;
	border-radius: $logan-border-radius-6;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
	border-radius: $logan-border-radius-6;
	border: $logan-border-width-2 solid $logan-destructive-enabled;
	background: rgba(236, 86, 86, 0.15);
}
