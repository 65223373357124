@import "../../../styles/build/scss/variables";

.modalBodyContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	max-width: 90%;
	max-height: 95vh;

	border-radius: $logan-border-radius-16;
	// TODO missing token
	border: $logan-border-width-4 solid #bee6cc;
	background: $logan-background-white;

	padding: $logan-spacing-32;
	gap: $logan-spacing-24;

	.footerContainer {
		gap: $logan-spacing-24;

		.closeBtn {
			border: $logan-border-width-2 solid $logan-button-primary-enabled;
			background: $logan-background-white;

			&:hover {
				border: $logan-border-width-2 solid $logan-button-primary-hover;
			}
		}

		p.closeBtnText {
			color: $logan-button-primary-enabled;
		}
	}
}
