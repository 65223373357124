@import "../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-16;
	// TODO missing token
	overflow: auto;

	.bodyContainer {
		flex: 1;
		border-radius: $logan-border-radius-16;
		border: $logan-border-width-1 solid $logan-outline-gray;
		background: $logan-background-white;
		// TODO missing token
		overflow: auto;

		.loanContainer {
			flex: 1;
			// TODO missing token
			overflow: auto;

			.headerContainer {
				display: grid;
				grid-template-columns: 1fr repeat(1, auto) 1fr;
				justify-items: center;

				padding: $logan-spacing-12 $logan-spacing-24;

				.headerTextContainer {
					flex-direction: row;
					align-items: center;
					grid-column-start: 2;

					.headerText {
					}
				}

				.closeIconContainer {
					margin-left: auto;
					cursor: pointer;

					padding: $logan-spacing-8;
					background-color: $logan-table-background-gray;
					border-radius: $logan-border-radius-4;

					.closeIcon {
						font-size: $logan-font-size-20;
						width: $logan-font-size-20;
						height: $logan-font-size-20;
					}
				}
			}
		}
	}
}
