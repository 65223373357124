@import "../../../styles/build/scss/variables";

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader,
.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingCheckbox {
	.MuiButtonBase-root.MuiCheckbox-root {
		color: $logan-check-box-outline;
	}

	.Mui-checked,
	.MuiCheckbox-indeterminate {
		color: $logan-check-box-fill;
	}
}
