@import "../.././styles/build/scss/variables";

div.tablePaperContainer {
	display: flex;
	flex-direction: column;
	border-top-left-radius: $logan-border-radius-10;
	border-top-right-radius: $logan-border-radius-10;
	border-bottom-left-radius: $logan-border-radius-0;
	border-bottom-right-radius: $logan-border-radius-0;
	box-shadow: none;

	.tableContainer {
		.table {
			border-collapse: separate;
		}

		th {
			background-color: $logan-table-background-gray;
			border-bottom: $logan-border-width-2 solid $logan-table-border-green;
		}

		tbody tr {
			.errorContainer {
				border-radius: 6px;
				border: 2px solid #ec5656;
				background: rgba(236, 86, 86, 0.15);
			}
		}

		.expandWidth {
			white-space: nowrap;
		}

		tbody td {
			border-bottom: $logan-border-width-1 solid $logan-table-border-gray;

			.greenText {
				color: $logan-text-dark-green;
			}

			.capitalize {
				text-transform: capitalize;
			}

			.overflowTextContainer {
				gap: $logan-spacing-8;
				justify-content: space-between;
				min-width: 275px;
				align-items: center;

				.truncatedText {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					color: $logan-text-dark-green;
				}

				.expandedText {
					white-space: break-spaces;
				}

				.truncatedIcon {
					border-radius: 4px;
					color: $logan-text-gray;
					background-color: #efefef;
					cursor: pointer;
				}
			}

			.editIconBtn {
				padding: $logan-spacing-0;

				.editIcon {
					color: $logan-outline-green;
					font-size: $logan-font-size-24;
				}
			}

			.deleteIconBtn {
				padding: $logan-spacing-0;

				.deleteIcon {
					color: $logan-destructive-enabled;
					font-size: $logan-font-size-24;
				}
			}

			.folderNameContainer {
				gap: $logan-spacing-6;

				.folderIcon {
					font-size: $logan-font-size-20;
					color: $logan-check-box-outline;
				}
			}

			.fileNameContainer {
				gap: $logan-spacing-6;

				.fileIcon {
					font-size: $logan-font-size-16;
					color: $logan-text-gray;
				}
			}

			.actionsContainer {
				gap: $logan-spacing-8;

				.iconBtn {
					padding: $logan-spacing-6;

					.deleteIcon {
						color: $logan-destructive-enabled;
						font-size: $logan-font-size-24;
					}
				}
			}

			.checkboxInput {
				margin: 0px auto;
			}

			.disabledText {
				color: $logan-table-text-gray;
				cursor: not-allowed;
				text-decoration: none;
			}

			.fieldContainer {
				// Missing token
				background-color: #f5f5f5;
				padding: $logan-spacing-12 $logan-spacing-10;
				border: 0;
				border-radius: 6px;
				height: 48px;
				min-width: 264px;
				width: 100%;
				white-space: nowrap;
			}

			.disabledContainer {
				-webkit-text-fill-color: rgba(0, 0, 0, 0.38);
			}

			.errorContainer {
				border-radius: 6px;
				border: 2px solid #ec5656;
				background: rgba(236, 86, 86, 0.15);
			}
		}

		.textCenter {
			text-align: center;

			.uploadIconContainer {
				display: inline-block;
				border-radius: $logan-border-radius-6;
				background: $logan-background-white;
				padding: $logan-spacing-4;
				cursor: pointer;

				&:hover {
					border-radius: $logan-border-radius-8;
					background: $logan-table-background-gray;
				}

				.uploadIcon {
					font-size: $logan-font-size-24;
				}
			}
		}

		.minimizedWidth {
			width: 1%;
			white-space: nowrap;
		}

		.pipelineNumber {
			cursor: pointer;
			text-decoration: underline;
		}

		.conditionTimestamp {
			color: $logan-text-dark-green;
		}

		.conditionTimestampLink {
			text-decoration: underline;
			color: $logan-link-enabled;
			cursor: pointer;
		}

		tbody > tr {
			&:hover {
				background-color: transparent;
			}
		}

		.conditionRow {
			background-color: transparent;
		}

		tbody > tr.highlightRow {
			border: 2px solid #2ecc71;
			background: rgba(190, 230, 204, 0.4);
		}

		tbody > tr.errorRow {
			border: 2px solid #ec5656;
			background: rgba(236, 86, 86, 0.15);
		}
	}
}

div.squaredBorderContainer {
	border-radius: $logan-border-radius-0;
}

div.maxHeight {
	max-height: 364px;
}

.incompleteStatusContainer {
	gap: $logan-spacing-8;
	border-bottom: $logan-border-width-1 solid #ec5656;
	width: fit-content;
}
