@import "../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;

	.bodyContainer {
		display: flex;
		flex-direction: column;
		border-top: $logan-border-width-1 solid $logan-outline-gray;
		padding: $logan-spacing-24;
		gap: $logan-spacing-32;
		height: 0px;

		flex: 1 1 auto;
		overflow: auto;

		.cardHeaderContainer {
			gap: $logan-spacing-4;
		}

		.headerLinkContainer {
			align-items: center;
			justify-content: space-between;
		}

		.uploadedFileContainer {
			gap: $logan-spacing-16;

			.fileListContainer {
				align-items: center;
				width: 50%;
				padding-left: $logan-spacing-16;
				gap: $logan-spacing-16;

				.fileIconContainer {
					padding: $logan-spacing-8;

					.fileIcon {
						font-size: $logan-font-size-24;
						color: $logan-outline-green;
					}
				}

				.fileNameContainer {
					gap: $logan-spacing-4;
				}

				.deleteIconContainer {
					padding: $logan-spacing-8;

					.deleteIcon {
						font-size: $logan-font-size-24;
						color: $logan-destructive-enabled;
					}
				}
			}
		}
	}
}

.footerContainer {
	justify-content: space-between;
	flex-wrap: wrap;
	border-top: $logan-border-width-1 solid $logan-outline-gray;
	background: $logan-background-white;
	padding: $logan-spacing-16 $logan-spacing-24;

	.prevIcon {
		color: $logan-text-dark-green;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}

	.nextIcon {
		color: $logan-background-white;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}
}
