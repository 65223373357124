@import "../../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-24 $logan-spacing-32;
	gap: $logan-spacing-24;
	overflow: auto;

	.drawerHeaderContainer {
		.headerText {
			color: #2c2b2b;
		}
	}

	.drawerBodyContainer {
		padding: $logan-spacing-16;
		gap: $logan-spacing-16;

		background: $logan-background-white;
		border-radius: $logan-border-radius-16;

		// TODO missing token
		border: $logan-border-width-1 solid #bee6cc;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

		.tabContainer {
			gap: $logan-spacing-24;
			max-width: 75vw;

			.tabBtnContainer {
				.tabLabelContainer {
					gap: $logan-spacing-8;
					align-items: center;

					.activeTabText {
						text-transform: capitalize;
						color: $logan-text-dark-green;
					}

					.tabText {
						text-transform: capitalize;
						color: $logan-text-gray;
					}

					.closeIconContainer {
						cursor: pointer;
						padding: $logan-spacing-4;
						background-color: $logan-table-background-gray;
						border-radius: $logan-border-radius-4;

						.closeIcon {
							font-size: $logan-font-size-12;
							width: $logan-font-size-12;
							height: $logan-font-size-12;
						}
					}
				}
			}

			.tabPanelContainer {
				display: flex;
				flex-direction: column;
				height: 100%;

				flex: 1 1 auto;
				overflow: auto;

				.tabPanelBody {
					padding-right: $logan-spacing-16;
					gap: $logan-spacing-16;

					.tabPanelRow {
						justify-content: space-between;

						.tabPanelText {
							color: $logan-table-text-gray;
						}

						.tabPanelValue {
							color: $logan-text-black;
						}
					}
				}
			}
		}
	}
}
