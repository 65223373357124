@import "../../../styles/build/scss/variables";

.cardContainer {
	.cardHeaderContainer {
		gap: $logan-spacing-4;
		margin-bottom: $logan-spacing-32;
	}

	.formSectionContainer {
		gap: $logan-spacing-48;

		.inputSectionContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-20 $logan-spacing-24;
			justify-content: space-between;
		}

		.inputContainer {
			gap: $logan-spacing-6;
		}

		.fullWidthContainer {
			min-width: 264px;
			width: 100%;
		}

		.inlineContainer {
			min-width: 264px;
			width: calc(50% - 12px);
		}
	}
}

.errorIcon {
	font-size: $logan-font-size-20;
	color: $logan-destructive-enabled;
}