.pageContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #dadada;
	height: 100vh;
}

.bodyContainer {
	display: flex;
	flex-direction: row;
	background-color: #f5f5f5;
	box-shadow: 0px 3.61625px 14.465px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	height: 500px;
	width: 900px;
}

.bodyLeftContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	background: linear-gradient(148.28deg, #c0cfba 2.53%, #9cadc5 102.09%);
	box-shadow: 0px 3.61625px 14.465px rgba(0, 0, 0, 0.08);
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
}

.imageContainer {
	margin-top: 16px;
	margin-left: 28px;
	margin-bottom: 48px;
	width: 200px;
	aspect-ratio: 2/1;
}

.skeletonContainer {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.bodyRightContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.rightTopContainer {
	display: flex;
	justify-content: flex-end;
}

.formContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin-top: 32px;
}

.formBodyContainer {
	width: 100%;
	max-width: 375px;
}

.resetText {
	font-family: "Sofia Sans";
}

.formFieldsContainer {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.inputFieldContainer {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.inputLabel {
	font-family: "Sofia Sans";
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.25rem;
	color: #6a6c70;
	margin-bottom: 8px;
}

.inputField {
	background-color: #fff;
	border: 1px solid #a9aaaf;
	border-radius: 8px;

	font-family: "Sofia Sans";
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.25rem;

	padding: 0.875rem 1rem;
}

.submitBtn {
	background: rgba(156, 177, 147, 0.8);
	width: 100%;
	margin-top: 20px;

	font-family: "Sofia Sans";
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.25rem;

	color: #ffffff;

	padding: 12px;
	border-radius: 8px;

	cursor: pointer;

	outline: 0;
	border: 0;
}

.footerBtn {
	background: rgba(156, 177, 147, 0.8);
	width: 100%;
	color: #ffffff;
	padding: 12px;
	border-radius: 8px;
	cursor: pointer;
}
