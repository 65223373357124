@import "../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-16;
	gap: $logan-spacing-24;
	border-radius: $logan-border-radius-16;

	// TODO missing token
	border: $logan-border-width-1 solid #bee6cc;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

	.topContainer {
		.tabTitle {
			// TODO missing token
			color: #2c2b2b;
		}

		.topBtnContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-32;

			.extendLockIcon {
				font-size: $logan-font-size-20;
			}

			.primaryBtnIcon {
				color: $logan-background-white;
			}
		}
	}

	.bottomContainer {
		// margin-left: $logan-spacing-16;
		flex: 1;
		column-gap: $logan-spacing-64;
		flex-wrap: wrap;

		.sectionHeaderLine {
			margin: $logan-spacing-24 $logan-spacing-0;
		}

		.infoDetailContainer {
			gap: $logan-spacing-48 $logan-spacing-16;
			padding: $logan-spacing-16 $logan-spacing-0;

			.infoDetailTitle {
				// TODO missing token
				min-width: 250px;

				.labelText {
					// TODO missing token
					color: #656565;
				}
			}

			.infoDetailValue {
				gap: $logan-spacing-16;
				flex-wrap: wrap;
				align-items: center;

				.datepicker {
					width: 264px;
					height: 48px;
					font-size: $logan-font-size-16;

					// TODO missing token
					background: #f5f5f5;
					border: 0px;
					border-radius: $logan-border-radius-6;
					padding: $logan-spacing-12 $logan-spacing-10;
					color: $logan-text-dark-green;

					input {
						color: $logan-text-dark-green;
					}

					div,
					button {
						// TODO missing token
						background-color: #f5f5f5;
						border: 0px;
						border-radius: $logan-border-radius-6;
					}

					button.react-calendar__tile--active {
						background-color: $logan-button-primary-enabled;
						color: $logan-text-white;
					}
				}

				.disabledDatepicker {
					// TODO missing token
					color: #989898;

					input {
						// TODO missing token
						color: #989898;
					}
				}
			}

			.fullWidthContainer {
				width: 100%;
			}

			.inlineContainer {
				min-width: 264px;
				width: calc(50% - 12px);
			}

			.valueText {
				// TODO missing token
				color: #252525;
			}
		}

		.titleContainer {
			padding: $logan-spacing-16 $logan-spacing-0;
			// flex-wrap: wrap;
		}

		.historyContainer {
			gap: $logan-spacing-24;
			padding: $logan-spacing-24 $logan-spacing-16;
		}
	}
}
