@import "../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;

	.bodyContainer {
		display: flex;
		flex-direction: column;
		border-top: $logan-border-width-1 solid $logan-outline-gray;
		height: 0px;

		flex: 1 1 auto;
		overflow: auto;

		.compensationContainer {
			justify-content: space-between;
			align-items: end;
			padding: $logan-spacing-24;

			.pricingBody {
				white-space: pre-wrap;
				margin: $logan-spacing-8 0;
			}

			.pricingButton {
				width: 210px;
			}
		}

		.tabBtnContainer {
			.tabLabelContainer {
				gap: $logan-spacing-8;
				align-items: center;

				.activeTabText {
					text-transform: capitalize;
					color: $logan-text-dark-green;
				}

				.tabText {
					text-transform: capitalize;
					color: $logan-text-gray;
				}

				.closeIconContainer {
					cursor: pointer;
					padding: $logan-spacing-4;
					background-color: $logan-table-background-gray;
					border-radius: $logan-border-radius-4;

					.closeIcon {
						font-size: $logan-font-size-12;
						width: $logan-font-size-12;
						height: $logan-font-size-12;
					}
				}
			}
		}

		.activeTabBtnContainer {
			background-color: #E2FFEE;
		}

		.formContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-20 $logan-spacing-24;
			justify-content: space-between;

			.inputContainer {
				gap: $logan-spacing-6;
				flex-wrap: wrap;
			}

			.inlineContainer {
				min-width: 264px;
				width: calc(50% - 12px);
			}
		}

		.formStartContainer {
			justify-content: flex-start;
		}

		.mortgageHeaderContainer {
			gap: 90px;
			align-items: center;
			margin-bottom: 40px;
		}

		.mortgageInput {
			width: 210px;
		}

		.xmlNote {
			margin-bottom: $logan-spacing-32;
		}
	}
}

.tabPanelContainer {
	display: flex;
	flex-direction: column;
	border-top: $logan-border-width-1 solid $logan-outline-gray;
	height: 0px;

	flex: 1 1 auto;
	overflow: auto;
}

.footerContainer {
	justify-content: space-between;
	flex-wrap: wrap;
	border-top: $logan-border-width-1 solid $logan-outline-gray;
	background: $logan-background-white;
	padding: $logan-spacing-16 $logan-spacing-24;

	.prevIcon {
		color: $logan-text-dark-green;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}

	.nextIcon {
		color: $logan-background-white;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}
}