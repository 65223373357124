@import "../../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-24 $logan-spacing-32;
	gap: $logan-spacing-24;
	overflow: auto;

	.drawerHeaderContainer {
		.headerText {
			color: #2c2b2b;
		}
	}

	.drawerBodyContainer {
		padding: $logan-spacing-24;
		gap: $logan-spacing-8;

		background: $logan-background-white;
		border-radius: $logan-border-radius-16;
		// TODO missing token
		border: $logan-border-width-1 solid #bee6cc;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

		.headerContainer {
			padding: $logan-spacing-8 $logan-spacing-0;
		}

		.labelText {
			color: $logan-text-black;
		}

		.subText {
			color: $logan-text-gray;
		}

		.changeCommentInput {
			> div {
				padding: $logan-spacing-0;
			}

			textarea {
				padding: $logan-spacing-24;
				border-radius: $logan-border-radius-16;
				border: $logan-border-width-2 solid rgba(46, 204, 113, 0.5);
				background-color: #f9f9f9;
			}

			fieldset {
				border-radius: $logan-border-radius-16;
				border: $logan-border-width-2 solid rgba(46, 204, 113, 0.5);
			}
		}
	}

	.drawerFooterContainer {
		gap: $logan-spacing-24;

		.cancelBtn {
			border: 2px solid $logan-button-secondary-enabled;

			.cancelText {
				color: $logan-button-secondary-enabled;
			}
		}
	}
}
