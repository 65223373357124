@import "../../../styles/build/scss/variables";

p.heading6 {
	font-family: $logan-font-families-sofia-sans;
	font-weight: $logan-font-weights-regular;
	font-size: $logan-font-size-14;
	line-height: $logan-line-heights-20;
	letter-spacing: $logan-spacing-0;
	color: $logan-text-gray;
}

p.semibold {
	font-weight: $logan-font-weights-semibold;
}
