@font-face {
	font-family: "Sofia Sans";
	src: url("./assets/fonts/SofiaSansSemiCond-Regular.woff2") format("woff2"),
		url("./assets/fonts/SofiaSansSemiCond-Regular.ttf") format("truetype"),
		url("./assets/fonts/SofiaSansSemiCond-Regular.otf") format("OpenType");
	font-weight: 400;
}

@font-face {
	font-family: "Sofia Sans";
	src: url("./assets/fonts/SofiaSansSemiCond-SemiBold.woff2") format("woff2"),
		url("./assets/fonts/SofiaSansSemiCond-SemiBold.ttf") format("truetype"),
		url("./assets/fonts/SofiaSansSemiCond-SemiBold.otf") format("OpenType");
	font-weight: 600;
}

* {
	box-sizing: border-box;
}

html {
	font-size: 16px;
	height: 100%;
	scroll-behavior: smooth;
}

@media screen and (max-width: 1200px) {
	html {
		font-size: 14px;
	}
}

body {
	height: 100%;
	margin: 0;
	font-family: "Sofia Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
	width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f9f9f9;
	box-shadow: 1px 0px 0px 0px #e7e7e7 inset;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #c2c2c2;
	border-radius: 8px;
	border: 4px transparent solid;
	background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #7a7a7a;
	border-radius: 8px;
	border: 4px transparent solid;
	background-clip: padding-box;
}
