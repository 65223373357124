@import "../.././styles/build/scss/variables";

.rootContainer {
	display: flex;
	min-height: 100vh;

	.mainContainer {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	header.appBar {
		background-color: $logan-background-white;
		border-bottom: $logan-border-width-2 solid $logan-check-box-outline;

		> div {
			padding: $logan-spacing-16 $logan-spacing-24;
		}

		button.menuIcon {
			color: $logan-side-menu-outline;
		}

		.toolbarStack {
			flex: 1;

			.userRoleText {
				text-transform: none;
				white-space: nowrap;
			}

			.breadcrumbContainer {
				.breadcrumb {
					cursor: pointer;
				}
				.breadcrumbIconContainer {
					padding: $logan-spacing-2 $logan-spacing-8 $logan-spacing-0
						$logan-spacing-8;

					.breadcrumbIcon {
						color: $logan-text-light-gary;
						font-size: $logan-font-size-12;
					}
				}
			}

			.organizationDropdown {
				min-width: 300px;
			}
		}
	}
}

.drawerContainer {
	> div {
		// Your styles for the MuiDrawer paper element here
		background-color: $logan-side-menu-background;
		border-right: $logan-border-width-2 solid $logan-table-border-green;
	}
}

.drawerMobileContainer {
	> div:nth-child(3) {
		// Your styles for the mobile MuiDrawer paper element here
		background-color: $logan-side-menu-background;
		border-right: $logan-border-width-2 solid $logan-table-border-green;
	}
}

hr.dividerLine {
	border-right: $logan-border-width-1 solid $logan-table-border-green;
	background-color: $logan-side-menu-seperator;

	// TODO missing token
	margin: $logan-spacing-16 20px;
}
