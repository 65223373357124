@import "../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-16;
	gap: $logan-spacing-24;

	.addUserContainer {
		align-items: center;
		justify-content: space-between;
		padding: $logan-spacing-24 $logan-spacing-20;
		gap: $logan-spacing-24;
		border: $logan-border-width-2 solid $logan-outline-gray;
		border-radius: $logan-border-radius-16;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);

		.manageUserBtnContainer {
			gap: $logan-spacing-16;
		}
	}

	.removeUserIcon {
		color: $logan-destructive-enabled;
	}

	.removeBtn {
		border: $logan-border-width-2 solid $logan-destructive-enabled;

		&:hover {
			border: $logan-border-width-2 solid $logan-destructive-enabled;
		}

		.removeBtnText {
			color: $logan-destructive-enabled;
		}
	}

	.addUserIcon {
		color: $logan-text-white;
	}

	.bodyContainer {
		flex: 1;
		padding: $logan-spacing-24;
		gap: $logan-spacing-24;
		border: $logan-border-width-2 solid $logan-outline-gray;
		border-radius: $logan-border-radius-16;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);

		.bodyTopContainer {
			justify-content: space-between;
			align-items: center;

			.bodyHeaderContainer {
				gap: $logan-spacing-4;
			}

			.databaseBtn {
				padding: $logan-spacing-8 $logan-spacing-16;
			}

			.databaseIcon {
				font-size: $logan-font-size-24;
				color: $logan-text-dark-green;
			}
		}

		.searchContainer {
			gap: $logan-spacing-16 $logan-spacing-48;
			flex-wrap: wrap;

			.searchInputContainer {
				flex: 1;
				gap: $logan-spacing-24;

				.inputContainer {
					flex: 1;
					gap: $logan-spacing-6;
				}

				.orContainer {
					align-items: center;
				}
			}

			.searchBtnContainer {
				align-items: flex-end;

				.searchBtn {
					padding: $logan-spacing-8 $logan-spacing-16 $logan-spacing-8
						$logan-spacing-8;
				}

				.searchIcon {
					font-size: $logan-font-size-24;
					color: $logan-button-primary-icon;
				}
			}
		}

		.footerContainer {
			width: 100%;
			justify-content: flex-end;
			gap: $logan-spacing-16;
			flex-wrap: wrap;

			.footerBtn {
				min-width: 186px;
			}
		}

		.tableContainer {
			flex: 1 1 auto;
			height: 75px;
		}
	}
}
