@import "../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	gap: $logan-spacing-24;
	padding: $logan-spacing-16;

	.tableContainer {
		flex: 1;
		padding: $logan-spacing-16;
		gap: $logan-spacing-24;
		border: $logan-border-width-2 solid $logan-outline-gray;
		border-radius: $logan-border-radius-16;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
		overflow: hidden;

		.loanTableHeader {
			padding: $logan-spacing-8 $logan-spacing-0;
		}

		.headerBtnContainer {
			gap: $logan-spacing-16;

			.backIcon {
				font-size: $logan-font-size-16;
				color: $logan-text-dark-green;
			}

			.addIcon {
				font-size: $logan-font-size-24;
				color: $logan-text-white;
			}
		}
	}
}
