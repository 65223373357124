@import "../../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-24 $logan-spacing-32;
	gap: $logan-spacing-24;
	overflow: auto;

	.drawerHeaderContainer {
		.headerText {
			color: #2c2b2b;
		}
	}

	.drawerBodyContainer {
		padding: $logan-spacing-24;
		gap: $logan-spacing-8;

		background: $logan-background-white;
		border-radius: $logan-border-radius-16;
		// TODO missing token
		border: $logan-border-width-1 solid #bee6cc;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

		.headerContainer {
			padding: $logan-spacing-8 $logan-spacing-0;
		}

		.labelText {
			color: $logan-text-black;
		}

		.subText {
			color: $logan-text-gray;
		}

		.changeCommentInput {
			> div {
				padding: $logan-spacing-0;
			}

			textarea {
				padding: $logan-spacing-24;
				border-radius: $logan-border-radius-16;
				border: $logan-border-width-2 solid rgba(46, 204, 113, 0.5);
				background-color: #f9f9f9;
			}

			fieldset {
				border-radius: $logan-border-radius-16;
				border: $logan-border-width-2 solid rgba(46, 204, 113, 0.5);
			}
		}

		.datepickerContainer {
			.datepicker {
				width: 264px;
				height: 48px;

				font-family: $logan-font-families-sofia-sans;
				font-weight: $logan-font-weights-semibold;
				font-size: $logan-font-size-16;
				line-height: $logan-line-heights-24;
				letter-spacing: $logan-spacing-0;

				// TODO missing token
				background: #f5f5f5;
				border: 0px;
				border-radius: $logan-border-radius-6;
				padding: $logan-spacing-12 $logan-spacing-10;
				color: $logan-text-dark-green;

				input {
					color: $logan-text-dark-green;
				}

				div,
				button {
					// TODO missing token
					background-color: #f5f5f5;
					border: 0px;
					border-radius: $logan-border-radius-6;
				}

				button.react-calendar__tile--active {
					background-color: $logan-button-primary-enabled;
					color: $logan-text-white;
				}
			}
		}
	}

	.drawerFooterContainer {
		gap: $logan-spacing-24;

		.cancelBtn {
			border: 2px solid $logan-button-secondary-enabled;

			.cancelText {
				color: $logan-button-secondary-enabled;
			}
		}
	}
}
