@import "../../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-24 $logan-spacing-32;
	gap: $logan-spacing-24;
	overflow: auto;
	height: 100vh;

	.drawerHeaderContainer {
		.headerText {
			color: #2c2b2b;
		}
	}

	.drawerBodyContainer {
		padding: $logan-spacing-16;

		background: $logan-background-white;
		border-radius: $logan-border-radius-16;
		// TODO missing token
		border: $logan-border-width-1 solid #bee6cc;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

		.greenText {
			color: $logan-text-dark-green;
			margin-bottom: $logan-spacing-16;
		}

		.activeTabText {
			text-transform: capitalize;
			color: $logan-text-dark-green;
		}

		.tabText {
			text-transform: capitalize;
			color: $logan-text-gray;
		}
	}
}

.tabPanelContainer {
	display: flex;
	flex-direction: column;
	border-top: $logan-border-width-1 solid $logan-outline-gray;

	flex: 1 1 auto;
	overflow: auto;

	.paymentsContainer {
		margin-top: $logan-spacing-16;
		gap: $logan-spacing-32;

		.cardContainer {
			border-radius: $logan-border-radius-16;
			// TODO missing token
			border: $logan-border-width-1 solid $logan-outline-gray;
			background: $logan-background-white;
			// TODO missing token
			box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
			padding: $logan-spacing-16;
			gap: $logan-spacing-16;

			.topContainer {
				gap: $logan-spacing-4;

				.addressText {
					color: $logan-text-dark-green;
				}

				.completeLoanContainer {
					gap: $logan-spacing-6;
					padding: $logan-spacing-8 $logan-spacing-0;
					align-items: center;

					.completeLoanLink {
						text-decoration: underline;
						color: $logan-link-enabled;
						cursor: pointer;
					}

					.completeLoanIcon {
						font-size: $logan-font-size-16;
						color: $logan-link-enabled;
						cursor: pointer;
					}
				}
			}

			.bottomContainer {
				flex-wrap: wrap;
				row-gap: $logan-spacing-24;

				.detailContainer {
					.detailDiv {
						gap: $logan-spacing-4;

						.detailsValue {
							color: $logan-text-dark-green;
						}
					}
				}

				.dividerContainer {
					padding: $logan-spacing-8 $logan-spacing-0;

					.dividerLine {
						border-right: $logan-border-width-1 solid #c1dcc6;
						height: 100%;
						margin: $logan-spacing-0 $logan-spacing-24;
					}
				}
			}
		}

		.tableContainer {
			gap: $logan-spacing-8;

			.tableHeader {
				color: $logan-text-dark-green;
			}
		}
	}

	.feesContainer {
		gap: $logan-spacing-24;
		margin-top: $logan-spacing-16;

		.sheetContainer {
			.headerRow {
				background-color: $logan-table-background-gray;
				border-bottom: $logan-border-width-1 solid $logan-table-border-green;
				border-radius: $logan-border-radius-8 $logan-border-radius-8
					$logan-border-radius-0 $logan-border-radius-0;
				align-items: flex-start;
				justify-content: space-between;
			}

			.rowCellLeft {
				padding: $logan-spacing-16;
			}

			.rowCellRight {
				padding: $logan-spacing-16 $logan-spacing-32;
			}

			.rowText {
				color: $logan-text-gray;
			}

			.rowSubtext {
				color: $logan-text-gray;
				opacity: 0.6;
			}

			.rowItemText {
				color: $logan-text-dark-green;
				line-height: $logan-line-heights-20;
			}

			.sheetRow1 {
				align-items: flex-start;
				justify-content: space-between;
				border-bottom: $logan-border-width-1 solid $logan-table-border-gray;
				padding-left: $logan-spacing-0;
			}

			.sheetRow2 {
				align-items: flex-start;
				justify-content: space-between;
				border-bottom: $logan-border-width-1 solid $logan-table-border-gray;
				padding-left: $logan-spacing-16;
			}

			.sheetRow3 {
				align-items: flex-start;
				justify-content: space-between;
				border-bottom: $logan-border-width-1 solid $logan-table-border-gray;
				padding-left: $logan-spacing-32;
			}

			.sectionTotalRow {
				align-items: flex-start;
				justify-content: space-between;
				border-top: 1px solid rgba(122, 122, 122, 0.6);
				background: $logan-table-background-gray;
			}

			.totalRow {
				align-items: flex-start;
				justify-content: space-between;
				border-top: 1px solid var(--outline-green, #76d99e);
				background-color: #def1e4;
				border-radius: $logan-border-radius-0 $logan-border-radius-0
					$logan-border-radius-8 $logan-border-radius-8;
				padding-left: $logan-spacing-0;
			}

			.greyRow {
				background-color: $logan-table-background-gray;
			}
		}
	}
}