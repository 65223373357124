@import "../../styles/build/scss/variables";

.formContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	height: 240px;

	border-radius: $logan-spacing-16;
	border: $logan-border-width-2 dashed rgba(46, 204, 113, 0.5);
	background: #f9f9f9;
	gap: $logan-spacing-8;

	cursor: pointer;

	.uploadIcon {
		font-size: 80px;
		color: $logan-text-gray;
	}

	.uploadTextContainer {
		padding: $logan-spacing-8;
		gap: $logan-spacing-8;

		.clickText {
			color: $logan-link-enabled;
			text-decoration: underline;
		}

		.uploadText {
			color: $logan-text-gray;
		}
	}
}
