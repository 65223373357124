@import "../../../styles/build/scss/variables";

.cardContainer {
	gap: $logan-spacing-32;

	.cardHeaderContainer {
		gap: $logan-spacing-4;
	}

	.sectionContainer {
		gap: $logan-spacing-24;

		.rowContainer {
			flex-wrap: wrap;

			// TODO missing token
			gap: 80px;

			.questionContainer {
				width: 100%;
				max-width: 400px;
				gap: $logan-spacing-8;

				label {
					white-space: normal;
				}
			}

			.subQuestion {
				margin-left: $logan-spacing-20;
				max-width: 380px;
			}

			.optionsContainer {
				gap: $logan-spacing-32;

				.declarationInput {
					width: 180px;
				}
			}
		}
	}
}
