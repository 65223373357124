@import "../../../styles/build/scss/variables";

.modalBodyContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 500px;

	border-radius: $logan-border-radius-16;
	// TODO missing token
	border: $logan-border-width-4 solid #bee6cc;
	background: $logan-background-white;

	padding: $logan-spacing-32;
	gap: $logan-spacing-24;

	.iconContainer {
		// TODO missing token
		font-size: 80px;
		color: $logan-button-primary-enabled;
	}

	.infoContainer {
		border-radius: 8px;
		border: $logan-border-width-2 solid $logan-outline-green;
		padding: $logan-spacing-16;
		gap: $logan-spacing-8;
		width: 100%;

		.loanOptionContainer {
			gap: $logan-spacing-12;

			.loanOptionText {
				color: $logan-text-gray;
			}

			.loanOptionValue {
				color: $logan-text-dark-green;
			}
		}

		.tableContainer {
			.table {
				border-collapse: separate;

				tbody td {
					.tableHeader {
						color: $logan-text-gray;
					}

					.tableValue {
						color: $logan-text-dark-green;
					}
				}

				tbody > tr {
					border-bottom: $logan-border-width-1 solid $logan-table-border-gray;
				}

				tbody > tr:nth-child(even) {
					background: $logan-table-background-gray;
				}

				tbody > tr:last-child td {
					border: 0px;
				}
			}
		}
	}

	.textContainer {
		gap: $logan-spacing-8;

		.confirmationText {
			color: $logan-text-gray;
		}
	}

	.closeBtn {
		border: $logan-border-width-2 solid $logan-button-primary-enabled;
		background: $logan-background-white;

		&:hover {
			border: $logan-border-width-2 solid $logan-button-primary-hover;
		}

		.closeBtnText {
			color: $logan-button-primary-enabled;
		}
	}

	p.closeBtnText {
		color: $logan-button-primary-enabled;
	}
}