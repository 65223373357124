@import "../../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-24 $logan-spacing-32;
	gap: $logan-spacing-24;
	overflow: auto;

	.drawerHeaderContainer {
		.headerText {
			color: #2c2b2b;
		}
	}

	.drawerBodyContainer {
		padding: $logan-spacing-16;
		gap: $logan-spacing-24;

		background: $logan-background-white;
		border-radius: $logan-border-radius-16;

		// TODO missing token
		border: $logan-border-width-1 solid #bee6cc;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

		.inputSectionContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-20 $logan-spacing-24;
			justify-content: space-between;

			.inputContainer {
				gap: $logan-spacing-6;
				flex-wrap: wrap;

				label {
					white-space: normal;
					// margin: 0;
				}

				.errorIcon {
					font-size: $logan-font-size-20;
					color: $logan-destructive-enabled;
				}
			}

			.fullWidthContainer {
				min-width: 264px;
				width: 100%;
			}

			.checkboxContainer {
				height: 48px;
			}

			.inlineContainer {
				flex: 1;
				min-width: 264px;
				width: calc(50% - 12px);
			}
		}

		.errorContainer {
			margin: $logan-spacing-0;
		}

		.footerContainer {
			width: 100%;
			justify-content: flex-end;
			gap: $logan-spacing-16;
			flex-wrap: wrap;

			.cancelBtn {
				min-width: 80px;
			}

			.addBtn {
				min-width: 140px;
			}
		}
	}
}
