@import "../.././styles/build/scss/variables";

.emptyContainer {
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: $logan-spacing-8;
  padding-top: $logan-spacing-20;
  padding-bottom: $logan-spacing-4;
 
  .emptyHeaderText {
    color: $logan-text-dark-green;
  }
  
  .emptyBodyText {
    color: $logan-text-gray;
  }
}
