.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
	padding: 6px 0px;
}

.react-datepicker__navigation--years-upcoming {
	border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-previous {
	border-top-color: #ccc;
}
