@import "../../styles/build/scss/variables";

.loanProgressContainer {
	flex: 1;
	padding: $logan-spacing-16;
	gap: $logan-spacing-24;
	border-left: $logan-border-width-1 solid $logan-outline-gray;
	background: $logan-table-background-gray;
	max-width: 225px;
	border-top-right-radius: $logan-border-radius-16;
	border-bottom-right-radius: $logan-border-radius-16;
	overflow: auto;

	.loanStepContainer {
		.loanStepDiv {
			gap: $logan-spacing-16;
			align-items: center;
			padding: $logan-spacing-4 $logan-spacing-0;

			.loanStepDot {
				font-size: $logan-font-size-12;
				color: $logan-outline-gray;
			}

			.enabledLoanStepDot {
				font-size: $logan-font-size-12;
				color: $logan-outline-green;
			}

			.loanStepText {
				line-height: $logan-line-heights-24;
				color: $logan-text-gray;
			}

			.enabledLoanStepText {
				line-height: $logan-line-heights-24;
				color: $logan-text-dark-green;
			}
		}

		.dividerContainer {
			display: flex;
			// should be the same as loanStepDot font-size
			width: $logan-font-size-12;
			align-items: center;
			justify-content: center;

			.loanStepDivider {
				width: 1.5px;
				height: 24px;
				background: $logan-outline-gray;
				border: 0px;
			}

			.enabledLoanStepDivider {
				width: 1.5px;
				height: 24px;
				background: $logan-outline-green;
				border: 0px;
			}
		}
	}
}
