
// Do not edit directly
// Generated on Fri, 30 Jun 2023 07:19:54 GMT

$logan-spacing-0: 0px;
$logan-spacing-2: 2px;
$logan-spacing-4: 4px;
$logan-spacing-6: 6px;
$logan-spacing-8: 8px;
$logan-spacing-10: 10px;
$logan-spacing-12: 12px;
$logan-spacing-16: 16px;
$logan-spacing-20: 20px;
$logan-spacing-24: 24px;
$logan-spacing-32: 32px;
$logan-spacing-48: 48px;
$logan-spacing-56: 56px;
$logan-spacing-64: 64px;
$logan-border-radius-0: 0px;
$logan-border-radius-2: 2px;
$logan-border-radius-4: 4px;
$logan-border-radius-6: 6px;
$logan-border-radius-8: 8px;
$logan-border-radius-10: 10px;
$logan-border-radius-12: 12px;
$logan-border-radius-16: 16px;
$logan-button-primary-icon: #ffffff;
$logan-button-primary-text: #ffffff;
$logan-button-primary-enabled: #26aa5e;
$logan-button-primary-hover: #1b9850;
$logan-button-primary-active: #d9d9d9;
$logan-button-primary-disabled: #d9d9d9;
$logan-button-secondary-enabled: #2ecc71;
$logan-button-secondary-hover: #1b9850;
$logan-button-secondary-active: #d9d9d9;
$logan-button-secondary-disabled: #d9d9d9;
$logan-button-secondary-icon-enabled: #2ecc71;
$logan-button-secondary-icon-hover: #1b9850;
$logan-button-secondary-icon-active: #d9d9d9;
$logan-button-secondary-icon-disabled: #d9d9d9;
$logan-button-secondary-text: #d9d9d9;
$logan-button-destructive-icon: #d9d9d9;
$logan-button-destructive-text: #d9d9d9;
$logan-button-destructive-enabled: #d9d9d9;
$logan-button-destructive-hover: #d9d9d9;
$logan-button-destructive-active: #d9d9d9;
$logan-button-destructive-disabled: #d9d9d9;
$logan-border-width-1: 1px;
$logan-border-width-2: 2px;
$logan-border-width-4: 4px;
$logan-check-box-outline: #2ecc71;
$logan-check-box-fill: #2ecc71;
$logan-check-box-icon: #ffffff;
$logan-text-black: #000000;
$logan-text-white: #ffffff;
$logan-text-gray: #7a7a7a;
$logan-text-dark-green: #203c2c;
$logan-text-light-gary: #c2c2c2;
$logan-input-text-large-outline: #2ecc71;
$logan-input-text-large-fill: #ffffff;
$logan-link-enabled: #6563ed;
$logan-background-white: #ffffff;
$logan-font-families-sofia-sans: Sofia Sans;
$logan-font-families-inter: Inter;
$logan-font-families-roboto-mono: Roboto Mono;
$logan-line-heights-16: 16px;
$logan-line-heights-20: 20px;
$logan-line-heights-24: 24px;
$logan-line-heights-28: 28px;
$logan-line-heights-32: 32px;
$logan-line-heights-40: 40px;
$logan-line-heights-52: 52px;
$logan-line-heights-auto: AUTO;
$logan-font-size-12: 12px;
$logan-font-size-14: 14px;
$logan-font-size-16: 16px;
$logan-font-size-20: 20px;
$logan-font-size-24: 24px;
$logan-font-size-32: 32px;
$logan-font-size-40: 40px;
$logan-letter-spacing-0: 0%;
$logan-paragraph-spacing-0: 0;
$logan-text-case-none: none;
$logan-text-decoration-none: none;
$logan-paragraph-indent-0: 0px;
$logan-font-weights-regular: 400;
$logan-font-weights-bold: 700;
$logan-font-weights-semibold: 600;
$logan-side-menu-background: #bee6cc;
$logan-side-menu-outline: #76d99e;
$logan-side-menu-seperator: #8ea295;
$logan-side-menu-selected-background: #b3d8c0;
$logan-side-menu-selected-text: #507d59;
$logan-side-menu-selected-border: #657d6d;
$logan-side-menu-unselected-background: #bee6cc;
$logan-side-menu-unselected-text: #5f5f5f;
$logan-outline-gray: #e9e9e9;
$logan-outline-green: #76d99e;
$logan-table-background-gray: #f9f9f9;
$logan-table-background-white: #ffffff;
$logan-table-border-green: #76d99e;
$logan-table-border-gray: #eaeaea;
$logan-table-text-gray: #6a6a6a;
$logan-tab1-selected-0-color: #bee6cc99;
$logan-tab1-selected-0-type: dropShadow;
$logan-tab1-selected-0-x: 0;
$logan-tab1-selected-0-y: 0;
$logan-tab1-selected-0-blur: 0;
$logan-tab1-selected-0-spread: 4;
$logan-tab1-selected-1-color: #bee6cc;
$logan-tab1-selected-1-type: dropShadow;
$logan-tab1-selected-1-x: 0;
$logan-tab1-selected-1-y: 0;
$logan-tab1-selected-1-blur: 0;
$logan-tab1-selected-1-spread: 2;
$logan-tab1-selected-2-color: #0000001a;
$logan-tab1-selected-2-type: dropShadow;
$logan-tab1-selected-2-x: 0;
$logan-tab1-selected-2-y: 0;
$logan-tab1-selected-2-blur: 1;
$logan-tab1-selected-2-spread: 0;
$logan-tab-tab1-outline: #bee6cc;
$logan-destructive-enabled: #ec5656;