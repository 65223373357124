@import "../../../styles/build/scss/variables";

.modalBodyContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 508px;

	border-radius: $logan-border-radius-16;
	// TODO missing token
	border: $logan-border-width-4 solid #bee6cc;
	background: $logan-background-white;

	padding: $logan-spacing-24;
	// TODO missing token
	gap: $logan-spacing-24;

	.modalHeadingContainer {
		gap: $logan-spacing-8;
	}

	.modalHeadingText {
		font-size: $logan-spacing-20;
		color: $logan-button-primary-enabled;
	}

	.modalHeadingIcon {
		color: $logan-button-primary-enabled;
		font-size: 24px;
	}

	.modalText {
		font-size: $logan-font-size-14;
	}

	.backButton {
		width: 100%;
		font-size: $logan-font-size-14;
	}

	.helpCentreText {
		color: $logan-text-dark-green;
		line-height: 18px;
	}

	.lisItemText {
		padding-left: $logan-spacing-0;
		font-family: $logan-font-families-sofia-sans;
	}

	.lineHeight18 {
		line-height: 18px;
	}
}
