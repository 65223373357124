@import "../../../styles/build/scss/variables";

.modalBodyContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 480px;

	border-radius: $logan-border-radius-16;
	// TODO missing token
	border: $logan-border-width-4 solid #bee6cc;
	background: $logan-background-white;

	padding: $logan-spacing-32;
	// TODO missing token
	gap: 40px;

	.animationContainer {
		width: 218px;
		height: 218px;
	}

	.textContainer {
		gap: $logan-spacing-8;

		.processingText {
			color: #26aa5e;
		}

		.processingSubText {
			color: $logan-text-gray;
		}
	}
}
