@import "../../styles/build/scss/variables";

.checkboxContainer {
	.checkbox {
		color: $logan-check-box-outline;
	}

	.labelStackContainer {
		align-items: center;
		gap: $logan-spacing-4;

		.checkboxSubLabel {
		}
	}

	.checkboxLabel {
	}

	.error {
		color: $logan-destructive-enabled;
	}
}
