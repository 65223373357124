@import "../../../styles/build/scss/variables";

.headerStack {
	gap: $logan-spacing-8;

	.tableLabel {
		flex-direction: row;
		justify-content: center;
	}

	.tableLabelLeft {
		flex-direction: row;
		justify-content: flex-start;
	}

	.tableSearchLabel {
		flex-direction: row;
	}

	p.thText {
		color: $logan-table-text-gray;
	}

	.inputField {
		border-radius: $logan-border-radius-8;
		border: $logan-border-width-2 solid $logan-outline-green;
		background: $logan-background-white;
		min-width: 264px;
		width: 100%;
		min-height: 48px;
	}

	.fullWidth {
		width: 100%;
	}
}
