@import "../../../styles/build/scss/variables";

.singleSelect {
	.selectText {
		color: $logan-text-dark-green;
		line-height: $logan-line-heights-24;
	}

	// TODO missing token
	background: #f5f5f5;
	border: 0;
	border-radius: $logan-border-radius-6;

	min-height: 48px;
	min-width: 264px;

	padding: $logan-spacing-12 $logan-spacing-10;

	&:hover {
		fieldset {
			border-radius: 8px;
			border: 2px solid #76d99e !important;
		}
	}

	fieldset {
		border-radius: 8px;
		border: 2px solid #76d99e;
	}
}
