@import "../../../styles/build/scss/variables";

.cardContainer {
	.cardHeaderContainer {
		gap: $logan-spacing-4;
		margin-bottom: $logan-spacing-32;
	}

	.formSectionContainer {
		gap: $logan-spacing-48;

		.inputSectionContainer {
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: space-between;

			overflow: auto;

			.addIconContainer {
				background-color: $logan-table-background-gray;
				padding: $logan-spacing-16;

				.addIconBtn {
					padding: $logan-spacing-0;

					.addIcon {
						color: $logan-outline-green;
						font-size: $logan-font-size-24;
					}
				}
			}

			.totalContainer {
				flex-direction: row;
				align-items: center;
				justify-content: flex-end;
				padding: $logan-spacing-16;
				gap: $logan-spacing-16;

				.totalText {
					color: $logan-text-dark-green;
				}

				.totalInput {
				}
			}
		}
	}
}
