@import "../../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-24 $logan-spacing-32;
	gap: $logan-spacing-24;
	overflow: auto;

	.drawerHeaderContainer {
		.headerText {
			color: #2c2b2b;
		}
	}

	.drawerBodyContainer {
		padding: $logan-spacing-16;
		gap: $logan-spacing-16;

		background: $logan-background-white;
		border-radius: $logan-border-radius-16;
		// TODO missing token
		border: $logan-border-width-1 solid #bee6cc;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

		.nameInputContainer {
			gap: $logan-spacing-24;
		}

		.inputContainer {
			gap: $logan-spacing-6;
			flex-wrap: wrap;

			.folderContainer {
				align-items: center;
				justify-content: space-between;
			}

			label {
				white-space: normal;
				// margin: 0;
			}
		}

		.uploadedFileContainer {
			gap: $logan-spacing-16;

			.fileListContainer {
				align-items: center;
				width: 100%;
				padding-left: $logan-spacing-16;
				gap: $logan-spacing-16;

				.fileIconContainer {
					padding: $logan-spacing-8;

					.fileIcon {
						font-size: $logan-font-size-24;
						color: $logan-outline-green;
					}
				}

				.fileNameContainer {
					gap: $logan-spacing-4;
				}

				.deleteIconContainer {
					padding: $logan-spacing-8;

					.deleteIcon {
						font-size: $logan-font-size-24;
						color: $logan-destructive-enabled;
					}
				}
			}
		}
	}

	.drawerFooterContainer {
		gap: $logan-spacing-24;

		.cancelBtn {
			border: 2px solid $logan-button-secondary-enabled;

			.cancelText {
				color: $logan-button-secondary-enabled;
			}
		}
	}
}
