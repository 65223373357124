@import "../../../styles/build/scss/variables";

.cardContainer {
	gap: $logan-spacing-32;

	.cardHeaderContainer {
		gap: $logan-spacing-4;
	}

	.sectionContainer {
		.rowContainer {
			.questionContainer {
				width: 100%;
				gap: $logan-spacing-8;

				label {
					white-space: normal;
				}
			}

			.optionsContainer {
				.subRowContainer {
					margin-left: $logan-spacing-32;
					margin-right: $logan-spacing-32;
					flex-wrap: wrap;

					.subColumnContainer {
						width: 100%;
						max-width: 296px;

						.otherInputContainer {
							margin-left: $logan-spacing-32;
						}
					}

					.inlineContainer {
						flex-wrap: wrap;
						gap: $logan-spacing-8;

						.otherInputContainer {
							margin-left: $logan-spacing-32;
						}
					}
				}
			}
		}
	}
}
