@import "../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	gap: $logan-spacing-16;
	padding: $logan-spacing-16;

	.bodyContainer {
		flex: 1;
		overflow: auto;

		border-radius: $logan-border-radius-16;
		border: $logan-border-width-2 solid $logan-outline-gray;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);

		.applicationTypeContainer {
			// TODO missing token
			gap: 80px;

			.applicationTypeHeader {
				gap: $logan-spacing-6;
				max-width: 240px;
				flex-wrap: wrap;
			}

			.btnContainer {
				gap: $logan-spacing-24;
				flex-wrap: wrap;

				.applicationTypeBtn {
					border-radius: $logan-border-radius-6;
					border: $logan-border-width-2 solid $logan-tab-tab1-outline;
					background: $logan-background-white;
				}

				.selectedApplicationTypeBtn {
					border-radius: $logan-border-radius-6;
					border: $logan-border-width-1 solid $logan-tab-tab1-outline;
					background: $logan-background-white;
					box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1),
						0px 0px 0px 4px rgba(190, 230, 204, 0.6);
				}
			}
		}

		.dividerContainer {
			margin: $logan-spacing-32 $logan-spacing-0;
		}

		.uploadedFileContainer {
			gap: $logan-spacing-16;

			.fileListContainer {
				align-items: center;
				width: 50%;
				padding-left: $logan-spacing-16;
				gap: $logan-spacing-16;

				.fileIconContainer {
					padding: $logan-spacing-8;

					.fileIcon {
						font-size: $logan-font-size-24;
						color: $logan-outline-green;
					}
				}

				.fileNameContainer {
					gap: $logan-spacing-4;
				}

				.deleteIconContainer {
					padding: $logan-spacing-8;

					.deleteIcon {
						font-size: $logan-font-size-24;
						color: $logan-destructive-enabled;
					}
				}
			}

			.footerBtnContainer {
				flex: 1;
				gap: $logan-spacing-16;
				justify-content: flex-end;
			}
		}

		.loanContainer {
			flex: 1;
			// TODO missing token
			overflow: auto;

			.headerContainer {
				display: grid;
				grid-template-columns: 1fr repeat(1, auto) 1fr;
				justify-items: center;

				padding: $logan-spacing-12 $logan-spacing-24;

				.headerTextContainer {
					flex-direction: row;
					align-items: center;
					grid-column-start: 2;

					.headerText {
					}
				}

				.closeIconContainer {
					margin-left: auto;
					cursor: pointer;

					padding: $logan-spacing-8;
					background-color: $logan-table-background-gray;
					border-radius: $logan-border-radius-4;

					.closeIcon {
						font-size: $logan-font-size-20;
						width: $logan-font-size-20;
						height: $logan-font-size-20;
					}
				}
			}
		}
	}

	.bodySpacing {
		gap: $logan-spacing-32;
		padding: $logan-spacing-24;
	}
}
