@import "../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-16;
	gap: $logan-spacing-24;
	border-radius: $logan-border-radius-16;

	// TODO missing token
	border: $logan-border-width-1 solid #bee6cc;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

	.topContainer {
		.tabTitle {
			// TODO missing token
			color: #2c2b2b;
		}

		.topBtnContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-32;

			.extendLockIcon {
				font-size: $logan-font-size-20;
			}

			.primaryBtnIcon {
				color: $logan-background-white;
			}
		}
	}

	.bottomContainer {
		// margin-left: $logan-spacing-16;
		flex: 1;
		column-gap: $logan-spacing-64;
		flex-wrap: wrap;

		.infoContainer {
			flex: 1;
			padding: $logan-spacing-24 $logan-spacing-16;

			.sectionHeaderLine {
				margin: $logan-spacing-24 $logan-spacing-0;
			}

			.infoDetailContainer {
				gap: $logan-spacing-48;
				padding: $logan-spacing-24 $logan-spacing-0;

				.infoDetailTitle {
					// TODO missing token
					min-width: 350px;
					flex: 1;

					.labelText {
						// TODO missing token
						color: #656565;
					}
				}

				.valueText {
					// TODO missing token
					color: #252525;
				}
			}
		}

		.historyContainer {
			gap: $logan-spacing-24;
			padding: $logan-spacing-24 $logan-spacing-16;
		}
	}
}
