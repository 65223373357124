@import "../../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;

	.bodyContainer {
		display: flex;
		flex-direction: column;
		border-top: $logan-border-width-1 solid $logan-outline-gray;
		padding: $logan-spacing-24;
		height: 0px;

		flex: 1 1 auto;
		overflow: auto;

		.cardHeaderContainer {
			gap: $logan-spacing-4;
			margin-bottom: $logan-spacing-32;
		}

		.formContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-20 $logan-spacing-24;
			justify-content: space-between;

			.formSectionContainer {
				flex: 1;
				flex-wrap: wrap;
				gap: $logan-spacing-20 $logan-spacing-24;

				.lpcIcon {
					font-size: $logan-font-size-24;
					color: transparent;
				}

				.bpcIcon {
					font-size: $logan-font-size-24;
				}
			}

			.sectionHeader {
				flex: 1;
				color: $logan-text-black;
				// TODO missing token
				min-width: 185px;
			}

			.compensationHeaderContainer {
				flex: 1;
				max-width: 185px;

				.compensationHeader {
					color: $logan-text-black;
				}
			}

			.inputContainer {
				gap: $logan-spacing-6;
				flex-wrap: wrap;

				.datepicker {
					background: #f5f5f5;
					width: 264px;
					height: 48px;
					font-size: $logan-font-size-16;

					// TODO missing token
					border: 0px;
					border-radius: $logan-border-radius-6;
					padding: $logan-spacing-12 $logan-spacing-10;
					color: $logan-text-dark-green;

					input {
						color: $logan-text-dark-green;
					}

					div,
					button {
						// TODO missing token
						background-color: #f5f5f5;
						border: 0px;
						border-radius: $logan-border-radius-6;
					}
				}

				.disabledDatepicker {
					// TODO missing token
					color: #989898;

					input {
						// TODO missing token
						color: #989898;
					}
				}

				.helperIconBtn {
					padding: $logan-spacing-0;
					margin-left: $logan-spacing-8;

					.helperIcon {
						font-size: $logan-font-size-20;
						color: $logan-button-primary-enabled;
					}
				}
			}

			.lpcContainer {
				margin: $logan-spacing-0 $logan-spacing-10;
				width: 264px;
			}

			.bpcContainer {
				margin: $logan-spacing-0 $logan-spacing-10;
				width: 264px;
			}

			.fullWidthContainer {
				width: 100%;
			}

			.inlineContainer {
				min-width: 316px;
				width: calc(50% - 12px);
			}
		}

		.datesContainer {
			justify-content: unset;
		}

		.feeFormContainer {
			gap: $logan-spacing-48;
		}
	}
}

.footerContainer {
	justify-content: space-between;
	// TODO missing token
	flex-wrap: wrap;
	border-top: $logan-border-width-1 solid $logan-outline-gray;
	background: $logan-background-white;
	padding: $logan-spacing-16 $logan-spacing-24;

	.prevIcon {
		color: $logan-text-dark-green;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}

	.nextIcon {
		color: $logan-background-white;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}
}
