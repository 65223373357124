@import "../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-16;
	gap: $logan-spacing-24;
	border-radius: $logan-border-radius-16;

	// TODO missing token
	border: $logan-border-width-1 solid #bee6cc;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

	.topContainer {
		.tabTitle {
			// TODO missing token
			color: #2c2b2b;
		}

		.topBtnContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-32;

			.extendLockIcon {
				font-size: $logan-font-size-20;
			}

			.primaryBtnIcon {
				color: $logan-background-white;
			}
		}
	}

	.bottomContainer {
		flex: 1;
		flex-wrap: wrap;

		.infoContainer {
			padding: $logan-spacing-24 $logan-spacing-16;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.infoDetailRow {
				flex: 1;
				min-height: 48px;

				.infoDetailCol {
					flex: 1;
				}

			.infoDetailTitle {
				// TODO missing token
				flex: 1;
				gap: $logan-spacing-24;
			}

			.labelText {
				// TODO missing token
				color: #656565;
			}

			.valueText {
				// TODO missing token
				color: #252525;
			}
		}
		}
		
		.sectionHeaderLine {
			margin: $logan-spacing-24 $logan-spacing-0;
		}
	}
}
