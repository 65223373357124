@import "../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-16;
	gap: $logan-spacing-24;
	border-radius: $logan-border-radius-16;

	border: $logan-border-width-1 solid #bee6cc;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

	.topContainer {
		.tabTitle {
			color: #2c2b2b;
		}

		.topBtnContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-32;

			.extendLockIcon {
				font-size: $logan-font-size-20;
			}

			.primaryBtnIcon {
				color: $logan-background-white;
			}
		}
	}

	.bottomContainer {
		flex: 1;

		.infoContainer {
			padding: $logan-spacing-16 $logan-spacing-16;

			.divider {
				margin: $logan-spacing-0 $logan-spacing-0 $logan-spacing-48
					$logan-spacing-0;
			}

			.containerHeader {
				margin-bottom: $logan-spacing-12;
			}

			.infoDetailContainer {
				gap: $logan-spacing-48;
				padding: $logan-spacing-16 $logan-spacing-0;

				.infoDetailTitle {
					min-width: 250px;

					.labelText {
						color: #656565;
					}
				}

				.valueText {
					color: #252525;
				}
			}
		}

		.historyContainer {
			gap: $logan-spacing-24;
			padding: $logan-spacing-24 $logan-spacing-16;
		}
	}
}
