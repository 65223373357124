@import "../../styles/build/scss/variables";

div.stackContainer {
	flex: 1;
	gap: $logan-spacing-24;
	padding: $logan-spacing-24;
	overflow: auto;

	.bodyContainer {
		gap: $logan-spacing-16;

		.tabsContainer {
			.tabContainer {
				.tabBtnContainer {
					padding: $logan-spacing-8;

					.tabBtn {
						// TODO missing token
						border: $logan-border-width-2 solid #bee6cc;
						border-radius: 6px;
					}

					.selectedTabBtn {
						// TODO missing token
						border-radius: 6px;
						border: 2px solid #bee6cc;
						background: #fff;
					}
				}
			}
		}
	}
}
