@import "../../../styles/build/scss/variables";

button.tertiaryBtn {
	background-color: #feffdd;

	// TODO missing design token
	border: $logan-border-width-2 solid #ac3e55;

	border-radius: $logan-border-radius-6;
	padding: $logan-spacing-8 $logan-spacing-12 $logan-spacing-8 $logan-spacing-8;
	gap: $logan-spacing-8;

	&:hover {
		// TODO missing design token
		background-color: #feffdd;
		border: $logan-border-width-2 solid #ac3e55;
	}

	&:disabled {
		border-radius: $logan-spacing-8;
		// TODO missing token
		border: $logan-border-width-2 solid #ac3e55;
		opacity: 0.5;
		background: #feffdd;
	}

	span {
		margin: $logan-spacing-0;
	}

	.tertiaryBtnText {
		// TODO missing token
		color: #203c2c;
		text-transform: $logan-text-case-none;
	}
}
