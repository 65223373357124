@import "../../../styles/build/scss/variables";

.cardContainer {
	border-radius: $logan-border-radius-16;
	// TODO missing token
	border: $logan-border-width-1 solid $logan-outline-gray;
	background: $logan-background-white;
	// TODO missing token
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
	padding: $logan-spacing-16;
	gap: $logan-spacing-16;

	.topContainer {
		gap: $logan-spacing-4;

		.addressText {
			color: $logan-text-dark-green;
		}

		.completeLoanContainer {
			gap: $logan-spacing-6;
			padding: $logan-spacing-8 $logan-spacing-0;
			align-items: center;

			.completeLoanLink {
				text-decoration: underline;
				color: $logan-link-enabled;
				cursor: pointer;
			}

			.completeLoanIcon {
				font-size: $logan-font-size-16;
				color: $logan-link-enabled;
				cursor: pointer;
			}
		}
	}

	.bottomContainer {

		.detailContainer {
			.detailDiv {
				gap: $logan-spacing-4;

				.detailsValue {
					color: $logan-text-dark-green;
				}
			}
		}
	}
}
