@import "../../../styles/build/scss/variables";

button.secondaryBtn {
	background-color: $logan-background-white;

	// TODO missing design token
	border: $logan-border-width-2 solid #909692;

	border-radius: $logan-border-radius-6;
	padding: $logan-spacing-8 $logan-spacing-12 $logan-spacing-8 $logan-spacing-8;
	gap: $logan-spacing-8;

	&:hover {
		// TODO missing design token
		// background-color: $logan-button-primary-hover;
		border: $logan-border-width-2 solid #909692;
	}

	&:disabled {
		border-radius: $logan-spacing-8;
		// TODO missing token
		border: $logan-border-width-2 solid #909692;
		opacity: 0.5;
		background: $logan-background-white;
	}

	span {
		margin: $logan-spacing-0;
	}

	.secondaryBtnText {
		// TODO missing token
		color: #203c2c;
		text-transform: $logan-text-case-none;
	}
}
