@import "../../../styles/build/scss/variables";

.stackContainer {
	.filterContainer {
		padding: $logan-spacing-16;
		gap: $logan-spacing-16;
		border-bottom: $logan-border-width-1 solid $logan-outline-green;
		background: $logan-side-menu-background;

		.filterHeader {
			color: $logan-side-menu-selected-text;
		}

		.filterInputContainer {
			gap: $logan-spacing-16;

			.inputContainer {
				flex: 1;
				gap: $logan-spacing-6;

				.inputHeader {
					color: $logan-side-menu-unselected-text;
					text-transform: capitalize;
				}

				.inputFieldContainer {
					width: 100%;
					min-width: 0px;
					background-color: $logan-background-white;

					&:hover {
						fieldset {
							border-radius: 8px;
							border: 0px !important;
						}
					}

					fieldset {
						border-radius: 8px;
						border: 0px;
					}
				}
			}
		}
	}

	.productAccordionContainer {
		padding: $logan-spacing-24 $logan-spacing-16;
		gap: $logan-spacing-24;

		.productAccordion {
			border-radius: $logan-border-radius-8;
			background: $logan-background-white;

			.productAccordionHeader {
				width: 100%;
				cursor: pointer;
				padding: $logan-spacing-16;
				align-items: center;
				justify-content: space-between;
				border: $logan-border-width-1 solid $logan-outline-gray;
				background: $logan-table-background-gray;

				.accordionIcon {
					color: $logan-text-gray;
				}
			}

			.borderExpanded {
				border-top-left-radius: $logan-border-radius-8;
				border-top-right-radius: $logan-border-radius-8;
			}

			.borderCollapsed {
				border-radius: $logan-border-radius-8;
			}
		}
	}
}
